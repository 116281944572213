import { postBodyRequest, getRequest } from '@/utils/axios'
// 客户分页
export function page (params) {
  return postBodyRequest('/cs/customer/page', params)
}
//客户列表
export function pageList (params) {
  return postBodyRequest('/cs/customer/list', params)
}
// 客户新增
export function customerAdd (params) {
  return postBodyRequest('/cs/customer/add', params)
}
// 客户详情
export function customerInfo (customerId) {
  return getRequest(`/cs/customer/${customerId}`)
}
// 客户编辑
export function customerEdit (params) {
  return postBodyRequest('/cs/customer/edit', params)
}
// 承运商车辆分页
export function truckPage (params) {
  return postBodyRequest('/cs/carrier/truck/page', params)
}
// 承运商车辆新增
export function truckAdd (params) {
  return postBodyRequest('/cs/carrier/truck/add', params)
}
// 承运商车辆编辑
export function truckEdit (params) {
  return postBodyRequest('/cs/carrier/truck/edit', params)
}
// 承运商车辆详情
export function truckInfo (carrierId,truckId) {
  return getRequest(`/cs/carrier/${carrierId}/truck/${truckId}`)
}
// 删除车辆
export function truckDelete (trunkId) {
  return getRequest(`/cs/carrier/${trunkId}/truck/delete`)
}
// 承运商司机新增
export function driverAdd (params) {
  return postBodyRequest('/cs/carrier/driver/add', params)
}
// 承运商司机编辑
export function driverEdit (params) {
  return postBodyRequest('/cs/carrier/driver/edit', params)
}
// 承运商司机分页
export function driverPage (params) {
  return postBodyRequest('/cs/carrier/driver/page', params)
}
// 承运商司机详情
export function driverInfo (carrierDriverId) {
  return getRequest(`/cs/carrier/driver/${carrierDriverId}`)
}
// 承运商司机删除
export function driverDelete(params) {
  return getRequest(`/cs/carrier/driver/delete`,params)
}

// 业务对接人新增
export function businessAdd (params) {
  return postBodyRequest('/cs/docking/business-docking/add', params)
}
// 业务对接人删除
export function businessDelete(params) {
  return getRequest(`/cs/docking/business-docking/delete`,params)
}
// 业务对接人编辑
export function businessEdit (params) {
  return postBodyRequest('/cs/docking/business-docking/edit', params)
}
// 业务对接人分页
export function businessPage (params) {
  return postBodyRequest('/cs/docking/business-docking/page', params)
}
// 业务对接人详情
export function businessInfo(businessDockId) {
  return getRequest(`/cs/docking/business-docking/${businessDockId}`)
}
// 业务对接人新增
export function carrierAdd (params) {
  return postBodyRequest('/cs/docking/carrier-docking/add', params)
}
// 承运商对接人删除
export function carrierDelete(params) {
  return getRequest(`/cs/docking/carrier-docking/delete`,params)
}
// 承运商对接人编辑
export function carrierEdit (params) {
  return postBodyRequest('/cs/docking/carrier-docking/edit', params)
}
// 承运商对接人分页
export function carrierPage (params) {
  return postBodyRequest('/cs/docking/carrier-docking/page', params)
}
// 承运商对接人详情
export function carrierInfo(carrierDockId) {
  return getRequest(`/cs/docking/carrier-docking/${carrierDockId}`)
}