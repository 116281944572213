<template>
<div>
  <div v-if="editTitle === '订单明细'">
    <div class="contentBox">
          <div>
            <ly-searchList :iptData="orderNewAdd" @searchAdd="searchOrder" @resetBtn="resetBtnOrd">
            </ly-searchList>
          </div>
          <div class="tips">
            <span>已选择<span style="margin: 0 5px;">{{ celOrder.length }}</span> 条</span>
            <span class="m-l2">总金额：<span style="color:red">￥{{ orderAmtNum }}</span></span>
            <a class="m-l4" @click="empty(0)">清空选择</a>
            <a-button type="primary" class="m-l3" :loading="loading" @click="addTrans">添加</a-button>
          </div>
          <div class="orderBox">
            <ly-table :columns="orderColumnsNew" @onChange="onChange" ref="tableRefOrd" size="small" rowKey="id" :rowSelectionShow="true" :data="orderData" :loading="loadingOrd">
              <template #settableAmt="{record}">
                <div>
                  <span v-show="record.editStatus"> {{record.settableAmt}}</span>
                  <a-input v-show="!record.editStatus" v-model:value="record.settableAmt" style="width:85px;" /><br />
                  <a @click="editBtn(record)" v-if="selectedOrderRowKeys.indexOf(record.id) > -1">{{record.editStatus ? '编辑' : '确认'}}</a>
                </div>
              </template>
              <template #startAddress="{ record }">
                {{ record.startAddress }}
                <div>{{ record.endAddress }}</div>
              </template>
             <template #vehicleList="{ record }">
              <div v-for="(item,index) in record.vehicleList" :key="index">
                  <a-tag color="#365DD2" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
                </div>
             </template>
              <template #settlementMode="{record}">
                <div>
                  <span v-show="record.editStatus"> {{record.settableAmt}}</span>
                  <a-input v-show="!record.editStatus" v-model:value="record.settableAmt" style="width:85px;" /><br />
                  <a @click="editBtn(record)" v-if="selectedOrderRowKeys.indexOf(record.id) > -1">{{record.editStatus ? '编辑' : '确认'}}</a>
                </div>
              </template>
            </ly-table>
          </div>
        </div>
  </div>
  <div v-else>
    <div class="contentBox">
          <div>
            <ly-searchList :iptData="tansIptData" @searchAdd="searchTran" @resetBtn="resetBtnTran">
            </ly-searchList>
          </div>
          <div class="tips">
            <span>已选择<span style="margin: 0 5px;">{{ celTrans.length }}</span> 条</span>
            <span class="m-l2">总金额：<span style="color:red">￥{{ transAmtNum }}</span></span>
            <a class="m-l4" @click="empty(1)">清空选择</a>
            <a-button type="primary" class="m-l3" :loading="loading" @click="addTrans">添加</a-button>
          </div>
          <div class="orderBox">
            <ly-table :columns="waybillColumnsNew" ref="tableRefTran" rowKey="id"  @onChange="onChangeTran" :rowSelectionShow="true"  size="small" :data="waybillData" :loading="loadingTran">
              <template #startAddress="{ record }">
                 {{ record.startAddress }}
                 <div>{{ record.endAddress }}</div>
              </template>
              <template #vehicleList="{ record }">
              <div v-for="(item,index) in record.vehicleList" :key="index">
                  <a-tag color="#365DD2" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
                </div>
             </template>
            </ly-table>
          </div>
        </div>
  </div>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { orderNewAdd, orderColumns, waybillColumns,tansIptData } from '../income'
import { reconciliationBillOrder, reconciliationBillTrans, reconciliationEdit } from '@/api/finance/income'
import { debounce, compute } from '@/utils/util'
import { message } from 'ant-design-vue';
import { cloneDeep } from 'lodash-es';
import { transitionTimeYMD } from '@/utils/util'

const props = defineProps({
  editTitle: {
    type: String,
    default: '',
  },
  customerId:{
    type: String,
    default: '',
  },
  editID:{
    type: String,
    default: '',
  }
});
const orderColumnsNew = orderColumns.value.slice(1, orderColumns.value.length)
const waybillColumnsNew = waybillColumns.value.slice(1, -1)
const emit = defineEmits(['submitSuccess'])
const tableRefOrd = ref(null)
const tableRefTran = ref(null)
const celOrder = ref([])
const selectedOrderRowKeys = ref([])
const celTrans = ref([])
const orderAmtNum = ref(0)
const transAmtNum = ref(0)
const loadingOrd = ref(false)
const loadingTran  = ref(false)
const orderData = ref([]) 
const waybillData = ref([])
const searchAMsgOrd = ref({})
const searchAMsgTran = ref({})
const loading = ref(false)

const searchOrder = (e) => {
  searchAMsgOrd.value = cloneDeep(e)
  searchAMsgOrd.value.startTime = transitionTimeYMD(e.startTime)
  searchAMsgOrd.value.endTime = transitionTimeYMD(e.endTime)
  empty(0)
  reqReconciliationBillOrder()
}
const resetBtnOrd = () => {
  searchAMsgOrd.value = {}
  empty(0)
  reqReconciliationBillOrder()
}

const searchTran = (e) => {
  searchAMsgTran.value = cloneDeep(e)
  empty(1)
  reqReconciliationBillTrans()
}
const resetBtnTran = () => {
  searchAMsgTran.value = {}
  empty(1)
  reqReconciliationBillTrans()
}
//添加
const addTrans = () => {
  if( celOrder.value.length === 0 && celTrans.value.length === 0){
    message.error('请选择勾选需要添加的明细')
    return
  }
  let orderSettlementIds = []
  celOrder.value.forEach(item => {
    orderSettlementIds.push({
      applyAmt: item.settableAmt,
      id:item.id
    })
  })
  let transSettlementIds = []
  celTrans.value.forEach(item => {
    transSettlementIds.push({
      applyAmt: item.settableAmt,
      id:item.id
    })
  })
 let addAmt = props.editTitle === '订单明细' ? orderAmtNum.value : transAmtNum.value  
  let msg = {
    amt:addAmt,
    orderSettlementIds: orderSettlementIds,
    transSettlementIds: transSettlementIds,
    id: props.editID
  }
  loading.value = true
  reconciliationEdit(msg).then(res => {
    if (res.code !== 10000) return
    message.success('新增成功')
    emit('submitSuccess')
  }).finally(() => {
    loading.value = false
  })
}
const onChange = (e, v) => {
  selectedOrderRowKeys.value = e
  celOrder.value = v
  let orderAmt =  []
  celOrder.value.forEach( item => {
    if (Number(item.settableAmt) > Number(item.applyAmt)) {
      item.settableAmt = item.applyAmt
    }
    orderAmt.push(item.settableAmt)
  })
  orderAmtNum.value =  celOrder.value.length > 0 ? compute('+', ...orderAmt).result : 0
}
const onChangeTran = (e,v) => {
  celTrans.value = v
  let transAmt = []
  celTrans.value.forEach(item => {
    transAmt.push(item.settableAmt)
  })
  transAmtNum.value =  celTrans.value.length > 0 ? compute('+', ...transAmt).result : 0
}
// 订单信息
const reqReconciliationBillOrder = () => {
  loadingOrd.value = true
  reconciliationBillOrder(props.customerId, searchAMsgOrd.value).then(res => {
    if (res.code !== 10000) return
    orderData.value = res.data
    orderData.value.forEach(o => {
      o.editStatus = true
      o.applyAmt = o.settableAmt
    })
    console.log(res);
  }).finally(() => {
    loadingOrd.value = false
  })
}
// 运单信息
const reqReconciliationBillTrans = () => {
  loadingTran.value = true
  reconciliationBillTrans(props.customerId, searchAMsgTran.value).then(res => {
    if (res.code !== 10000) return
    waybillData.value = res.data
  }).finally(() => {
    loadingTran.value = false
  })
}
//清空选择
const empty = (type) => {
   if(type == 0){
    tableRefOrd.value.selectedRowKeys= []  
    selectedOrderRowKeys.value = []
    celOrder.value =  []
    orderAmtNum.value = 0
   }else{
    tableRefTran.value.selectedRowKeys= []
    celTrans.value =  []
    transAmtNum.value = 0
   }
}
// 新增账单结算金额编辑
const editBtn = (record) => {
  if(Number(record.applyAmt) < 0){
    message.error('不能修改负数')
    return
  }
  if (Number(record.settableAmt) > 0) {
    if (Number(record.settableAmt) > Number(record.applyAmt)) {
      message.error('数额高于最' + Number(record.applyAmt) + '大值')
      return
    }
    record.editStatus = !record.editStatus
    if (record.editStatus) {
      orderAmtNum.value = compute('+', ...celOrder.value.map(item => item.settableAmt)).result
    }
  } else { message.error('数额不能小于0') }
}

onMounted(() => {
  if(props.editTitle === '订单明细'){
    reqReconciliationBillOrder()
  }else{
    reqReconciliationBillTrans()
  }
})
</script>

<style lang="less" scoped>
:deep(.navBox) {
  padding: 0;
}
.title {
  font-size: 16px;
  font-weight: 700;
}
.tips {
  padding:  5px 0;
  font-size: 12px;
}
.orderBox{
  height: 650px;
  overflow: auto;
}

</style>